import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layouts/Blog/BlogPost/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`If you want to transform your life in a positive way, that transformation will not happen by itself. You need to make it happen.`}</p>
    <p>{`Self-discipline will be your best friend in this transformation.`}</p>
    <p>{`Many people often ask me:`}</p>
    <em>How can you wake up early in the morning?</em><br />
    <b>Wake up early in the morning.</b>
    <em>How can I workout consistently every single day?</em><br />
    <b>Workout consistently every single day.</b>
    <em>How do you stay motivated?</em><br />
    <b>My answer was simple: “I don’t.”</b>
    <p>{`There are days where I wake up and don’t have any motivation at all. Somedays, I want to stay in bed, browse social media, do nothing but procrastinate.`}</p>
    <p>{`Motivation does not help.
Motivation is fleeting. It comes and goes.
Motivation gets you started, discipline keeps you going.`}</p>
    <p>{`Self-discipline is your only option if you want to win.
Discipline starts in the mind.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Motivation is fleeting. It comes and goes.`}</p>
    </blockquote>
    <p>{`Elbert Hubbard defined self-discipline as `}<b>{`“the ability to do what you have to do, when you have to do it, whether you feel like it or not.”`}</b></p>
    <p>{`It is the one skill that is necessary above anything else to succeed in your life. You need to use your discipline in your daily life.`}</p>
    <p>{`How do you get more self-disciplined?
These are some points that help me everyday to keep my self-discipline.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/2000/0*WBROuMlJ5OpeOMQ1",
        "alt": "coffee"
      }}></img></p>
    <h2>{`Wake up early`}</h2>
    <p>{`No one wants to hear this, but step number one is to wake up early.
It does take discipline to get out of bed early, but that sets the tone and the pattern of discipline for the rest of the day.`}</p>
    <p>{`I start my day at 4:00–4:30 every day. Domenico, are you insane?`}</p>
    <p>{`Early morning is a special time for me. Everything is so quiet.
I am full of energy and ready to finish my first tasks before everyone is awake. It is a great start of the day.`}</p>
    <p>{`But, how can I wake up so early? I need 6–7–8–9 hours of sleep.
My answer is: `}<b>{`go to sleep earlier.`}</b></p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/10368/0*-ZNmIxb8aITBqCyB",
        "alt": "Exercise"
      }}></img></p>
    <h2>{`Exercise`}</h2>
    <p>{`The next thing to do is exercise — do something physical. Again, that takes discipline, but it gives you momentum in the right direction.`}</p>
    <p>{`It has a big impact on my body and mind. It pays off during the whole day.`}</p>
    <h2>{`Schedule your time`}</h2>
    <p>{`Everyone gets the same 24 hours. How you are using them is up to you.`}</p>
    <p>{`Everyone had the same feeling like there aren’t enough hours in the day. The days is finishing and you have a sensation that you could have done more.`}</p>
    <p>{`Before you continue, answer these questions:`}</p>
    <ul>
      <li parentName="ul">{`What are you doing with your time?`}</li>
      <li parentName="ul">{`Who are you spending it with?`}</li>
      <li parentName="ul">{`What are you spending it on?`}</li>
    </ul>
    <p>{`You are probably wasting time, energy and focus.`}</p>
    <p>{`One of the best advice for you is to put together a schedule or a task list.
Plan your daily tasks the night before.
Wake up and start executing them. One by one.`}</p>
    <p>{`You will have a great start of the day.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Plan your daily tasks the night before.`}</p>
    </blockquote>
    <p>{`An important rule for me is that the task needs to be a priority, important and non-negotiable. Anything else will have a hard time on my schedule.`}</p>
    <p>{`What gets scheduled gets done.`}</p>
    <blockquote>
      <p parentName="blockquote">{`We are what we repeatedly do.
Excellence, then, is not an act but a habit. — Aristotle`}</p>
    </blockquote>
    <h2>{`Say NO often`}</h2>
    <p>{`After scheduling, you need to learn to say NO.`}</p>
    <p>{`Why is that important?
Everyone wants part of your time for “important business” or other matters.`}</p>
    <p>{`If you want to have enough time for yourself, you need to say NO to what is not important. The more you start using it, the easier it gets.`}</p>
    <p>{`It is about being honest to yourself and your priorities.`}</p>
    <p>{`In this way, you can free more time and say YES to what really matters for you. Both in your private and professional life.`}</p>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/8576/0*cHy8mfwvny1iA6QJ",
        "alt": "Be Happy"
      }}></img></p>
    <h2>{`Positivity`}</h2>
    <p>{`Last but not least, stay positive. Always. No matter what.`}</p>
    <p>{`Negativity will not help you. Whatever the situation is, wherever you are.`}</p>
    <p>{`Pay attention and listen to your thoughts.`}</p>
    <p>{`Convert every negative or defeating thought into the a positive one. In the long run, the empowering thought becomes the dominant one.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`If you want to transform your life in a positive way, that transformation will not happen by itself. You need to make it happen.`}</p>
    <p>{`Self-discipline will be your best friend in this transformation. It will take care of you as nothing can.
And it will put you in the right path. A path of strength, health and happiness.`}</p>
    <p>{`Don’t be motivated: motivation is fleeting. It comes and goes.`}</p>
    <p>{`Be disciplined and hold yourself accountable.`}</p>
    <p>{`Make the decision. Make the commitment. Become the discipline.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Don’t be motivated, be disciplined and hold yourself accountable.`}</p>
    </blockquote>
    <p>{`It is all about self-discipline.
It is about the path you want.
You decide.
You are in control of your life.`}</p>
    <h2>{`Few steps for you:`}</h2>
    <ul>
      <li parentName="ul">
        <b>Wake up early</b>: choose the time you want to wake up. Go to sleep earlier, so you can get the hours of sleep you need.
      </li>
      <li parentName="ul">
        <b>Schedule your time</b>: Make your schedule. Plan what you need to do the night before. Wake up and act on them.
Basic rule for a task entering your schedule: It needs to have a priority in your life, non-negotiable and important.
      </li>
      <li parentName="ul">
        <b>Say NO</b>: say NO. Often. To everything that does not really matter in your life.
      </li>
      <li parentName="ul">
        <b>Positivity</b>: Stay Positive. No matter what. Keep in the positive side of your thoughts.
      </li>
    </ul>
    <h4>{`FOLLOW ME`}</h4>
    <p>{`Do you know that I have a YouTube channel? `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Subscribe!`}</a></p>
    <p>{`Where can you find me?`}</p>
    <ul>
      <li parentName="ul">{`Youtube: `}<a parentName="li" {...{
          "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
        }}>{`Domenico Solazzo's Channel`}</a></li>
      <li parentName="ul">{`Instagram: `}<a parentName="li" {...{
          "href": "https://www.instagram.com/domenicosolazzo/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Linkedin: `}<a parentName="li" {...{
          "href": "https://www.linkedin.com/in/solazzo/"
        }}>{`solazzo`}</a></li>
      <li parentName="ul">{`Medium: `}<a parentName="li" {...{
          "href": "https://medium.com/@domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Facebook: `}<a parentName="li" {...{
          "href": "https://www.facebook.com/domenicosolazzo.labs/"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Snapchat: `}<a parentName="li" {...{
          "href": "https://twitter.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Github: `}<a parentName="li" {...{
          "href": "https://github.com/domenicosolazzo"
        }}>{`domenicosolazzo`}</a></li>
      <li parentName="ul">{`Website: `}<a parentName="li" {...{
          "href": "https://www.domenicosolazzo.com"
        }}>{`https://www.domenicosolazzo.com`}</a></li>
      <li parentName="ul">{`Hashnode: `}<a parentName="li" {...{
          "href": "https://hashnode.com/@domenicosolazzo"
        }}>{`https://hashnode.com/@domenicosolazzo`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      